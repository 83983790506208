import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, useNavigate } from "react-router-dom";
import { inputChangePassword } from "../Validations/ChangePasswordValidation";
import { inputFieldValidation } from "../Validations/validation";
import jwt_decode from "jwt-decode";
import { getCodeHandle, changePasssword } from "../services/auth/auth";
import emailjs from "@emailjs/browser";
import { updateProfile } from "../services/user";
import env from "react-dotenv";

const Userprofile = () => {
  const serviceId = env.YOUR_SERVICE_ID;
  const templateId = env.YOUR_TEMPLATE_ID;
  const publicKey = env.YOUR_PUBLIC_KEY;

  const token = localStorage.getItem("jwtToken");

  const email = jwt_decode(token).email;
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
  }, []);
  const [passwordField, setPasswordField] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [oldPasswordValid, setOldPasswordValid] = useState("");
  const [newPasswordValid, setNewPasswordValid] = useState("");
  const [confirmPasswordValid, setConfirmPasswordValid] = useState("");
  const [name, setName] = useState("");
  const [emails, setEmails] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [emailsErr, setEmailsErr] = useState("");

  const [loginMethod, setLoginMethod] = useState("password");

  const [timerText, setTimerText] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [counter, setCounter] = useState(0);
  const [textForCode, setTextForCode] = useState("Get Code");
  const [tpin, setTpin] = useState("");
  const [tpinErr, setTpinErr] = useState("");
  const [pin, setPin] = useState("");
  const [pinErr, setPinErr] = useState("");

  const handlePassword = (e) => {
    const { name, value } = e.target;
    setPasswordField({ ...passwordField, [e.target.name]: value });

    const errorMessage = inputChangePassword(name, value);

    if (name === "old_password") {
      console.log(value, "value");
      setOldPasswordValid(errorMessage);
    } else if (name === "new_password") {
      setNewPasswordValid(errorMessage);

      if (value !== passwordField.confirm_password) {
        setConfirmPasswordValid("Confirm password doesn't matchs");
      } else {
        setConfirmPasswordValid("");
      }
    } else if (name === "confirm_password") {
      setConfirmPasswordValid(errorMessage);
      if (value !== passwordField.new_password) {
        setConfirmPasswordValid("Confirm password doesn't match");
      } else {
        setConfirmPasswordValid("");
      }
    }
  };
  const generateRandom6DigitCode = () => {
    const min = 100000; // Smallest 6-digit number
    const max = 999999; // Largest 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getCode = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("jwtToken");

    const email = jwt_decode(token).email;

    const randomCode = generateRandom6DigitCode();
    const emailParams = {
      from_name: "brain_chain",
      brainchain: "otp",
      serviceId: serviceId,
      templateId: templateId,
      userId: publicKey,
      recipientEmail: email,
      randomCode: randomCode,
    };

    // Send the email
    emailjs
      .send(
        emailParams.serviceId,
        emailParams.templateId,
        {
          to_email: emailParams.recipientEmail,
          from_name: emailParams.from_name,
          brainchain: emailParams.brainchain,
          message: emailParams.randomCode,
        },
        emailParams.userId
      )
      .then(async (response) => {
        const data = {
          email: emailParams.recipientEmail,
          tpin: randomCode,
        };
        const res = await getCodeHandle(data);
        if (res.status == true) {
          startCountdown(30);
          toast.dismiss();
          // setTimerText("")
          // setEmail(emailParams.recipientEmail);
          // setTpin(randomCode);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.error("Email sending error:", error);
      });
  };
  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      counter--;
      setCounter(counter);

      setButtonDisable(true);
      setTimerText("Resend In");
      if (counter <= 0) {
        clearInterval(interval);
        setTextForCode("Resend Code");
        setButtonDisable(false);

        setTimerText("");
        setCounter(0);
      }
    }, 1000);
  };
  useEffect(() => {
    if (counter > 0) {
      startCountdown(counter);
    }
  }, [counter]);

  const handleChange = (e) => {
    const { id, value, name } = e.target;
    if (id == "inputTPin") {
      const err = inputFieldValidation(id, value);
      setTpinErr(err);
      setTpin(value);
    }
    if (id == "inputPin") {
      const err = inputFieldValidation(id, value);
      setPinErr(err);
      setPin(value);
    }
    if (name == "name") {
      const err = inputFieldValidation(name, value);
      setNameErr(err);
      setName(value);
    }
    if (name == "emails") {
      var Email1Reg = new RegExp(
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
      ).test(value);
      if (!Email1Reg) {
        setEmailsErr("Enter valid Email Address");
        return false;
      }
      const err = inputFieldValidation(name, value);
      setEmailsErr(err);
     
      setEmails(value);
    }
  };

  const changePassswordHandler = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("jwtToken");

    const email = jwt_decode(token).email;

    let data = "";
    if (loginMethod == "password") {
      if (!passwordField.old_password) {
        setOldPasswordValid("This field is required");
        return;
      }

      if (!pin) {
        setPinErr("This field is required");
        return;
      }
      if (!passwordField.new_password) {
        setNewPasswordValid("This field is required");
        return;
      }

      if (!passwordField.confirm_password) {
        setConfirmPasswordValid("This field is required");
        return;
      }

      data = {
        OldPassword: passwordField.old_password,
        NewPassword: passwordField.new_password,
        CPassword: passwordField.confirm_password,
        email,
        pin,
        type: "password",
      };
    } else {
      if (!tpin) {
        setTpinErr("This field is required");
        return;
      }
      if (!passwordField.new_password) {
        setNewPasswordValid("This field is required");
        return;
      }

      if (!passwordField.confirm_password) {
        setConfirmPasswordValid("This field is required");
        return;
      }
      data = {
        tpin,
        NewPassword: passwordField.new_password,
        CPassword: passwordField.confirm_password,
        email,
        type: "otp",
      };
    }

    const res = await changePasssword(data, token);
    console.log(res, "ressss");
    if (res.status) {
      toast.dismiss();
      toast.success(res.message);

      localStorage.clear();
      navigate("/");
    } else {
      toast.dismiss();
      toast.error(res.message);
    }
  };

  const profileUpdate = async(e) => {
    e.preventDefault()
    const config = localStorage.getItem("jwtToken");
    const data = {
      name,
      email:emails,
    };
    var Email1Reg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      emails
    );
    if (!name) {
      setNameErr("This field is required");
      return false;
    }

    if (!emails) {
      setEmailsErr("This field is required");
      return false;
    }
    if (!Email1Reg) {
      setEmailsErr("Enter valid Email Address");
      return false;
    }
    console.log(data,"data")
    const res =await updateProfile(data, config);
    console.log(res,"res")
    if (res.status) {
      toast.success(res.message);
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex align-items-center">
              <h2 className="mb-0">
                <b>User Profile</b>
              </h2>
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Profile
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User Profile
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="active ml-auto back_btn">
                {" "}
                <Link className="btn_man" to="/dashboard">
                  Back
                </Link>
              </div>
            </div>
            <section className="content">
              <div className="row">
                <div className="col-12 col-lg-7 col-xl-8">
                  <div className="nav-tabs-custom">
                    <ul className="nav nav-tabs">
                      <li>
                        <a
                          className="active"
                          href="#usertimeline"
                          data-bs-toggle="tab"
                        >
                          Profile Settings
                        </a>
                      </li>
                      <li>
                        <a href="#settings" data-bs-toggle="tab" className="">
                          Password Change
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="usertimeline">
                        <div className="box no-shadow">
                          <form className="form-horizontal col-12">
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="XtD4AlPqc10nnY40MXzkJ9FO5BzWFImJ3mXmilbu"
                            />
                            <div className="form-group row">
                              <label
                                htmlFor="inputName"
                                className="col-sm-3 form-label"
                              >
                                Name
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  name="name"
                                  value={name}
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholder="Name"
                                />
                                {nameErr && (
                                      <span style={{ color: "red" }}>
                                        {nameErr}
                                      </span>
                                    )}
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-3 form-label"
                              >
                                Email
                              </label>
                              <div className="col-sm-9">
                                
                                <input
                                  type="email"
                                  className="form-control"
                                  name="emails"
                                  value={emails}
                                  onChange={handleChange}
                                  placeholder="Email Address"
                                />
                                  {emailsErr && (
                                      <span style={{ color: "red" }}>
                                        {emailsErr}
                                      </span>
                                    )}
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="ms-auto col-sm-9">
                                <button
                                  type="submit"
                                  className="btn_man"
                                  onClick={profileUpdate}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="tab-pane" id="settings">
                        <div className="box no-shadow">
                          <form className="form-horizontal ">
                            <input
                              type="hidden"
                              name="_token"
                              defaultValue="XtD4AlPqc10nnY40MXzkJ9FO5BzWFImJ3mXmilbu"
                            />

                            {loginMethod === "password" && (
                              <>
                                <div className="form-group row">
                                  <label
                                    htmlFor="password"
                                    className="col-sm-3 form-label"
                                  >
                                    Old Password
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="password"
                                      name="old_password"
                                      // value={pass}
                                      value={passwordField.old_password}
                                      className="form-control"
                                      onChange={handlePassword}
                                      id="inputName"
                                      placeholder="Old Password"
                                      required=""
                                    />
                                    {oldPasswordValid && (
                                      <span style={{ color: "red" }}>
                                        {oldPasswordValid}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    htmlFor="password"
                                    className="col-sm-3 form-label"
                                  >
                                    Pin
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control "
                                      id="inputPin"
                                      placeholder=" Enter PIN"
                                      required=""
                                      onChange={handleChange}
                                    />
                                    {pinErr && (
                                      <span
                                        style={{ color: "red" }}
                                        className="mb-5"
                                      >
                                        {pinErr}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            {loginMethod === "otp" && (
                              <div class="form-group row ">
                                <label
                                  htmlFor="password"
                                  className="col-sm-3 form-label"
                                >
                                  Tpin
                                </label>

                                <div className="col-sm-9">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="inputTPin"
                                      placeholder="TPIN"
                                      required=""
                                      onChange={handleChange}
                                      // onBlur={handleOnBlurTPin}
                                      disabled={loginMethod === "password"}
                                    />
                                    {console.log(textForCode, "textForCode")}
                                    <button
                                      type="submit"
                                      className="btn btn-info"
                                      onClick={getCode}
                                      disabled={buttonDisable}
                                    >
                                      {timerText}

                                      {counter === 0 ? textForCode : counter}
                                    </button>
                                  </div>

                                  {tpinErr && (
                                    <span style={{ color: "red" }}>
                                      {tpinErr}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="form-group row">
                              <label
                                htmlFor="password"
                                className="col-sm-3 form-label"
                              >
                                New Password
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="password"
                                  name="new_password"
                                  value={passwordField.new_password}
                                  className="form-control"
                                  onChange={handlePassword}
                                  id="inputEmail"
                                  placeholder="New Password"
                                  required=""
                                />
                                {newPasswordValid && (
                                  <span style={{ color: "red" }}>
                                    {newPasswordValid}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="password"
                                className="col-sm-3 form-label"
                              >
                                Confirm password
                              </label>
                              <div className="col-sm-9">
                                {/* {console.log(passwordField.confirm_password,"passwordField.confirm_password")} */}
                                <input
                                  type="password"
                                  onChange={handlePassword}
                                  name="confirm_password"
                                  className="form-control"
                                  value={passwordField.confirm_password}
                                  id="inputPhone"
                                  placeholder="Confirm Password"
                                  required=""
                                />
                                {confirmPasswordValid && (
                                  <span style={{ color: "red" }}>
                                    {confirmPasswordValid}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              {loginMethod === "password" ? (
                                <Link
                                  to=""
                                  onClick={() => setLoginMethod("otp")}
                                >
                                  By using TPIN
                                </Link>
                              ) : (
                                <Link
                                  to=""
                                  onClick={() => setLoginMethod("password")}
                                >
                                  Use Password
                                </Link>
                              )}
                              <div className="ms-auto col-sm-9">
                                <button
                                  type="submit"
                                  className="btn_man"
                                  onClick={changePassswordHandler}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.nav-tabs-custom */}
                </div>
                {/* /.col */}
                <div className="col-12 col-lg-5 col-xl-4">
                  {/*  <div className="box box-widget widget-user">
                   Add the bg color to the header using any of the bg-* classes */}
                  {/* <div
                      className="widget-user-header bg-img bbsr-0 bber-0"
                      style={{
                        background:
                          'url("../../assets/images/users/users_bg.jpg") center center',
                      }}
                      data-overlay={5}
                    >
                      <h3 className="widget-user-username text-white">
                        Admin{" "}
                      </h3>
                    </div> */}
                  {/* <div className="widget-user-image">
                      <img
                        className="rounded-circle"
                        src="../../assets/images/users/avatar-1.png"
                        alt="User Avatar"
                        style={{ border: "10px solid #ffffff" }}
                      />
                    </div> */}
                  {/* <div className="box-footer"></div> 
                  </div>*/}
                  <div className="box">
                    <div className="box-body box-profile">
                      <div className="row">
                        <div className="col-12">
                          <div>
                            <p>
                              Email :-
                              <span className="text-gray ps-10">
                                {email}
                              </span>{" "}
                            </p>
                            {/* <p>
                              Phone :<span className="text-gray ps-10" />
                            </p>
                            <p>
                              Country :<span className="text-gray ps-10" />
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.box-body */}
                  </div>
                </div>
              </div>
              {/* /.row */}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Userprofile;
