const inputFieldValidation = (name, value) => {
  let values = value;
  let err = "";
  if (!values || values === undefined) {
    err = "This field is required";
    return err;
  }
  if (value.match(/^\s/)) {
    err = `Please enter a valid ${name}`;
    return err;
  }
};

const ImageValidation = (name, value) => {
  let err = "";
  if (!value) {
    err = `This field is required`;
    return err;
  }
  if (value && value.name && !value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    err = `Please select valid image jpg|jpeg|png|gif`;
    return err;
  }
};

export { inputFieldValidation, ImageValidation };
