import * as opsService from "./Ops";

import { idoRequests, statusUpdate,projectDetails,IdoAdd,getIdoDatas } from "../Constant/Api";

const getIdoRequest = async (data, token) => {
  let result = await opsService.getData(idoRequests, data, token);

  return result;
};
const statusUpdates = async (data, token) => {
  let result = await opsService.postdata(statusUpdate, data, token);

  return result;
};
  const projectDetail = async (data, token) => {
    let result = await opsService.postdata(projectDetails, data, token);

    return result;
  };
  const IdoAddUpdate = async (data, token) => {
    let result = await opsService.postdata(IdoAdd, data, token);

    return result;
  };

  const getIdoData = async (data, token) => {
    let result = await opsService.getData(getIdoDatas, data, token);
  
    return result;
  };
  



export { getIdoRequest, statusUpdates,projectDetail,IdoAddUpdate,getIdoData };
