import React, { Suspense, useState, useEffect } from "react";
import DashboardDataFetcher from "./DashboardDataFetcher";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";

import { dashboardData } from "../services/user";
import { Card, Skeleton } from "antd";

function Dashboard() {
  // const [approve, setApprove] = useState("");
  // const [inQueue, setInQueue] = useState("");
  // const [approvedByAdmin, setApprovedByAdmin] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
  }, []);

  // const getData = async () => {
  //   try {
  //     const config = localStorage.getItem("jwtToken");
  //     const res = await dashboardData(config);

  //     if (res.status) {
  //       const { approvedProject } = res.getData[0] || {};
  //       const { queProject } = res.getQueProject[0] || {};
  //       const { adminApproved } = res.getApprovedProject[0] || {};

  //       setApprove(approvedProject || 0);
  //       setInQueue(queProject || 0);
  //       setApprovedByAdmin(adminApproved || 0);
  //     } else {
  //       console.error("Error fetching data:", res.error);
  //     }
  //   } catch (error) {
  //     console.error("Exception while fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);
  const DashboardContent = () => {
    const { approve, inQueue, approvedByAdmin } = DashboardDataFetcher();

    return (
      <div className="row dashbord_man">
        <div className="row dashbord_man">
          <div className="col-md-3 col-sm-6 col-12 card-space">
            <div className="card card-animate">
              <Link to="/approve">
                <div className="card-body">
                  <p className="fw-medium mb-0">Total Project</p>
                  <div className="d-flex justify-content-between count-outer">
                    <div>
                      <h2 className="ff-secondary fw-semibold">
                        <span className="counter-value" data-target={100}>
                          {approve ? approve : "0"}
                        </span>
                      </h2>
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title rounded-circle fs-2">
                          <span className="mdi mdi-account-group-outline"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 card-space">
            <div className="card card-animate">
              <Link to="/project-in-queue">
                <div className="card-body">
                  <p className="fw-medium mb-0">Project in queue</p>
                  <div className="d-flex justify-content-between count-outer">
                    <div>
                      <h2 className="ff-secondary fw-semibold">
                        <span className="counter-value" data-target={100}>
                          {inQueue ? inQueue : "0"}
                        </span>
                      </h2>
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title rounded-circle fs-2">
                          <span className="mdi mdi-currency-usd"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 card-space">
            <div className="card card-animate">
              <Link to="/approved-by-admin">
                <div className="card-body">
                  <p className="fw-medium mb-0">Approved project by admin</p>
                  <div className="d-flex justify-content-between count-outer">
                    <div>
                      <h2 className="ff-secondary fw-semibold">
                        <span className="counter-value" data-target={100}>
                          {approvedByAdmin ? approvedByAdmin : "0"}
                        </span>
                      </h2>
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title rounded-circle fs-2">
                          <span className="mdi mdi-percent"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid mt-2">
            <div className="section-heading d">
              <h2 className="mb-0">
                <b>Dashboard</b>
              </h2>
            </div>
            <Suspense fallback={<Skeleton active />}>
              <DashboardContent />
            </Suspense>
            {/* <div className="row dashbord_man">
              <div className="col-md-3 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/approve">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Project</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {approve ? approve : "0"}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span className="mdi mdi-account-group-outline"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/project-in-queue">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Project in queue</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {inQueue ? inQueue : "0"}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span className="mdi mdi-currency-usd"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/approved-by-admin">
                    <div className="card-body">
                      <p className="fw-medium mb-0">
                        Approved project by admin
                      </p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {approvedByAdmin ? approvedByAdmin : "0"}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span className="mdi mdi-percent"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Dashboard;
