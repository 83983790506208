import React,{useEffect, useState} from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { Link, useNavigate } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";
import {getprojectDataInQueue} from "../services/Projects"

const ProjectInQueue = () => {
    const [record,setRecord]= useState([])

    const columns = [
      {
        key: "Sr No.",
        text: "Sr. No.",
        className: "sr_no.",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "project_name",
        text: "Project Name",
        className: "sr_no.",
        align: "left",
        sortable: true,
        // cell: (record) => {
         
        //   return <>{record.name}</>;
        // },
      },
      
      {
        key: "blockchain",
        text: "Blockchain",
        className: "cust_name",
        align: "left",
        sortable: true,
      },
  
      // {
      //   key: "created_at",
      //   text: "Created Date",
      //   className: "color",
      //   align: "left",
      //   sortable: true,
      //     cell: (record) => {
      //       return <>{moment(record.created_at).format("LLL")}</>;
      //     },
      // },
     
    ];
    const config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Download",
      no_data_text: "No user found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        // length_menu: "Show MENU result per page",
        filter: "Search in records...",
        // info: "Showing START to END of TOTAL records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };
    const pageChange = (pageData) => {};
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!localStorage.getItem("jwtToken")) {
        navigate("/");
      } 
    }, []);
    useEffect(() => {
      getUsers()
    }, []);
    const getUsers = async()=>{
        const config = localStorage.getItem("jwtToken");
       const res= await getprojectDataInQueue(config);
       console.log(res,"res")
       if(res.status){
        setRecord(res.getData)
       }
      }

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex align-items-center">
              <h2 className="mb-0">
                <b>Project in queue</b>
              </h2>
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Project in queue
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Project in queue
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="active ml-auto back_btn">
                {" "}
                <Link className="btn_man" to="/dashboard">
                  Back
                </Link>
              </div>
            </div>

            <div className="box">
              <div className="box-body">
                <div className="">
                  <div className="">
                    <div className="table-responsive">
                      <ReactDatatable
                        config={config}
                          records={record}
                        columns={columns}
                        onPageChange={pageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectInQueue
