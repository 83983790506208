import React, { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  projectDataById,
  projectsCopy,
  getAccessType,
} from "../services/Projects";
import { toast } from "react-toastify";

export const NewProjectAdd = () => {
  const { id } = useParams();
  const initialValues = {
    token_address: "",
    blockchain_type: "",
    total_supply: "",
    project_logo: "",
    project_name: "",
    total_raise: "",
    max_allocation: "",
    min_allocation: "",
    total_distribution: "",
    incubation_option: "",
    sale_starts_in: "",
    sale_ends_in: "",
    project_summary: "",
    vesting_details: "",
    website: "",
    twitter: "",
    telegram: "",
    discord: "",
    participants: "",
    token_name: "",
    token_symbol: "",
    token_decimal: "",
    token_price: "",
    token_equivalent_usdt: "",
    // allocation_start_date: "",
    // allocation_start_time: "",
    // allocation_ending_hours: "",
    token_no: "",
    smart_contract_for_IDO: "",
    access_type: "",
    // allocation_xtimes: "",
  };
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [access, setAccess] = useState([]);
  const [accessType, setAccessType] = useState([
    "Private",
    "Private FCFS",
    "Private FCFS 2",
    "Public",
    "Public FCFS",
    "Public FCFS 2",
  ]);
  const [accessDisabled, setAccessDisabled] = useState("");

  const filteredAccessType = accessType.filter(
    (value) => !access.some((item) => item.access_type === value)
  );
  const [formData, setFormData] = useState(initialValues);

  console.log(formData, "formDataaa");
  const [errors, setErrors] = useState(initialValues);

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    idData(id);
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let error = "";

    if (value === "") {
      error = "This field is required.";
    }

    if (name === "project_logo") {
      const file = e.target.files[0];

      setFormData({
        ...formData,
        [name]: file,
      });
    } else {
      if (name === "token_price") {
        const tokenPrice = parseFloat(value);
        const tokenEquivalentUsdt = tokenPrice !== 0 ? 1 / tokenPrice : "";
        setFormData({
          ...formData,
          [name]: value,
          token_equivalent_usdt: tokenEquivalentUsdt,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };
  const handleUpdateHandler = async (e) => {
    try {
      e.preventDefault();

      let isValid = true;
      const newErrors = {};

      for (const key in formData) {
        if (formData[key] == "") {
          newErrors[key] = "This field is required.";
          isValid = false;
          break;
        }
      }

      if (formData.project_logo && formData.project_logo.size > 1024 * 1024) {
        toast.dismiss();
        toast.error(
          "'File size exceeds 1MB limit. Please choose a smaller file.'"
        );
      }

      setErrors(newErrors);

      if (isValid) {
        setBtnDisabled(true);
        const {
          token_address,
          blockchain_type,
          total_supply,
          project_logo,
          project_name,
          total_raise,
          max_allocation,
          min_allocation,
          total_distribution,
          incubation_option,
          sale_starts_in,
          sale_ends_in,
          project_summary,
          vesting_details,
          website,
          twitter,
          telegram,
          discord,
          participants,
          token_name,
          token_symbol,
          token_decimal,
          token_price,
          token_equivalent_usdt,
          // allocation_start_date,
          // allocation_start_time,
          // allocation_ending_hours,
          token_no,
          smart_contract_for_IDO,
          access_type,
          // allocation_xtimes,
        } = formData;

        const unixTimestamp = Math.floor(
          new Date(formData.sale_starts_in).getTime() / 1000
        );
        const unixTimestamp2 = Math.floor(
          new Date(formData.sale_ends_in).getTime() / 1000
        );
        const currentTime = new Date().getTime() / 1000;
        if (
          parseFloat(formData.min_allocation) >=
          parseFloat(formData.max_allocation)
        ) {
          toast.error("Max Allocation should be greater than Min Allocation");
          return;
        }

        if (currentTime > unixTimestamp) {
          toast.error("Invalid sale start time ");
          setBtnDisabled(false);
          return;
        }
        if (currentTime > unixTimestamp2) {
          toast.error("Invalid sale end time ");
          setBtnDisabled(false);
          return;
        }
        if (unixTimestamp >= unixTimestamp2) {
          toast.error("Sale end time should be greater than Sale start time");
          setBtnDisabled(false);
          return;
        }
        const noStartingSpaceRegex = /^[^\s].*$/;
        if (!noStartingSpaceRegex.test(formData.project_name)) {
          toast.error("Space not allowed");
          return;
        }

        // const allocationStrt = Math.floor(
        //   new Date(formData.allocation_start_date).getTime() / 1000
        // );
        // const allocationEnds = Math.floor(
        //   new Date(formData.allocation_start_time).getTime() / 1000
        // );
        const formDatas = new FormData();

        formDatas.append("token_address", token_address);
        formDatas.append("blockchain_type", blockchain_type);
        formDatas.append("total_supply", total_supply);
        formDatas.append("project_logo", project_logo);

        formDatas.append("project_name", project_name);
        formDatas.append("total_raise", total_raise);
        formDatas.append("max_allocation", max_allocation);
        formDatas.append("min_allocation", min_allocation);
        formDatas.append("total_distribution", total_distribution);
        formDatas.append("incubationOption", incubation_option);

        formDatas.append("sale_starts_in", unixTimestamp);
        formDatas.append("sale_ends_in", unixTimestamp2);
        formDatas.append("project_summary", project_summary);
        formDatas.append("vesting_details", vesting_details);
        formDatas.append("website", website);
        formDatas.append("twitter", twitter);
        formDatas.append("telegram", telegram);
        formDatas.append("discord", discord);
        formDatas.append("participants", participants);

        formDatas.append("token_name", token_name);
        formDatas.append("token_symbol", token_symbol);
        formDatas.append("token_decimal", token_decimal);
        formDatas.append("token_price", token_price);
        formDatas.append("token_equivalent_usdt", token_equivalent_usdt);
        // formDatas.append("allocation_start_date", allocationStrt);

        // formDatas.append("allocation_start_time", allocationEnds);
        // formDatas.append("allocation_ending_hours", allocation_ending_hours);
        formDatas.append("token_no", token_no);

        formDatas.append("smart_contract_for_IDO", smart_contract_for_IDO);

        formDatas.append("access_type", access_type);

        // if (access_type == "Public" || access_type == "Private") {
        //   formDatas.append("allocation_xtimes", "Null");
        // } else {
        //   formDatas.append("allocation_xtimes", allocation_xtimes);
        // }

        formDatas.append("id", id);

        const config = localStorage.getItem("jwtToken");
        const res = await projectsCopy(formDatas, config);

        if (res.status) {
          toast.dismiss();
          setBtnDisabled(false);
          toast.success(res.message);

          navigate("/approve");
        } else {
          toast.dismiss();
          setBtnDisabled(false);
          toast.error(res.message);
        }
      }
    } catch (err) {
      toast.error(err);
    }
  };
  useEffect(() => {
    getAccesss();
  }, []);

  const getAccesss = async () => {
    const config = localStorage.getItem("jwtToken");
    let data = { id: id };
    const resp = await getAccessType(data, config);

    if (resp.status) {
      setAccess(resp.data);
    }
  };
  const idData = async (id) => {
    const data = {
      id,
    };
    const config = localStorage.getItem("jwtToken");
    const res = await projectDataById(data, config);

    if (res.status) {
      const { tokenLogo, ...otherData } = res.data[0];
      setFormData({
        ...formData,
        tokenLogo,

        ...otherData,
      });

      const timestamp = res.data[0].sale_starts_in * 1000;
      const timestamp1 = res.data[0].sale_ends_in * 1000;
      // const timestamp2 = res.data[0].allocation_start_date * 1000;
      // const timestamp3 = res.data[0].allocation_start_time * 1000;
      const date = new Date(timestamp);

      const formattedDate = `${date.getFullYear()}-${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
      const formattedTime = `${("0" + date.getHours()).slice(-2)}:${(
        "0" + date.getMinutes()
      ).slice(-2)}`;

      const formattedDateTime = `${formattedDate}T${formattedTime}`;
      const datee = new Date(timestamp1);

      const formattedDate1 = `${datee.getFullYear()}-${(
        "0" +
        (datee.getMonth() + 1)
      ).slice(-2)}-${("0" + datee.getDate()).slice(-2)}`;
      const formattedTime1 = `${("0" + datee.getHours()).slice(-2)}:${(
        "0" + datee.getMinutes()
      ).slice(-2)}`;

      const formattedDateTime1 = `${formattedDate1}T${formattedTime1}`;

      // const datee2 = new Date(timestamp2);
      // const formattedDate2 = `${datee2.getFullYear()}-${(
      //   "0" +
      //   (datee2.getMonth() + 1)
      // ).slice(-2)}-${("0" + datee2.getDate()).slice(-2)}`;
      // const formattedTime2 = `${("0" + datee2.getHours()).slice(-2)}:${(
      //   "0" + datee2.getMinutes()
      // ).slice(-2)}`;

      // const formattedDateTime2 = `${formattedDate2}T${formattedTime2}`;

      // const datee3 = new Date(timestamp3);
      // const formattedDate3 = `${datee3.getFullYear()}-${(
      //   "0" +
      //   (datee3.getMonth() + 1)
      // ).slice(-2)}-${("0" + datee3.getDate()).slice(-2)}`;
      // const formattedTime3 = `${("0" + datee3.getHours()).slice(-2)}:${(
      //   "0" + datee2.getMinutes()
      // ).slice(-2)}`;

      // const formattedDateTime3 = `${formattedDate3}T${formattedTime3}`;

      setFormData((prevFormData) => ({
        ...prevFormData,
        sale_starts_in: formattedDateTime,
        sale_ends_in: formattedDateTime1,
        // allocation_start_date: formattedDateTime2,
        // allocation_start_time: formattedDateTime3,
        access_type: "",
      }));
      setAccessDisabled(res?.data[0]?.access_type);
    }
  };
  const options = [];
  for (let i = 1; i <= 24; i++) {
    options.push(
      <option key={i} value={i}>
        {i} Hours
      </option>
    );
  }
  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex align-items-center">
              <h2 className="mb-0">
                <b>Copy Project Details</b>
              </h2>
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Project
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Copy Project
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="active ml-auto back_btn">
                {" "}
                <Link className="btn_man" to="/approve">
                  Back
                </Link>
              </div>
            </div>
            <div className="box alert_message_step">
              <div className="box-body">
                <div className="">
                  <div className="">
                    <form>
                      <div className="box-body gray-box-body">
                        <div className="row">
                          <div className="form-group">
                            <h4>Token Details</h4>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="token_address"
                                className="form-label"
                              >
                                Token Contract
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="token_address"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.token_address}
                                id="token_address"
                              />
                              {errors.token_address && (
                                <span style={{ color: "red" }}>
                                  {errors.token_address}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="blockchain_type"
                                className="form-label"
                              >
                                BlockChain Type
                              </label>
                              <select
                                className="form-select"
                                name="blockchain_type"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.blockchain_type}
                              >
                                <option>Select</option>
                                <option value="BSC"> BSC </option>
                                <option value="ETH"> ETH </option>
                                <option value="POLYGON"> POLYGON </option>
                              </select>
                              {errors.blockchain_type && (
                                <span style={{ color: "red" }}>
                                  {errors.blockchain_type}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="total_supply"
                                className="form-label"
                              >
                                Total Supply
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="total_supply"
                                onChange={handleChange}
                                // onKeyPress={validNumber}
                                autoComplete="off"
                                value={formData.total_supply}
                                id="total_supply"
                              />
                              {errors.total_supply && (
                                <span style={{ color: "red" }}>
                                  {errors.total_supply}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="whitelist" className="form-label">
                                Project Logo
                              </label>
                              <img
                                autoComplete="off"
                                src={formData.project_logo}
                                id="imgSample"
                                alt=""
                              />
                              <input
                                className="form-control"
                                type="file"
                                name="project_logo"
                                id="project_logo"
                                onChange={handleChange}
                                // value={}
                              />
                              {errors.project_logo && (
                                <span style={{ color: "red" }}>
                                  {errors.project_logo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="projectName"
                                className="form-label"
                              >
                                Project Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="project_name"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.project_name}
                                id="project_name"
                              />
                              {errors.project_name && (
                                <span style={{ color: "red" }}>
                                  {errors.project_name}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="total_raise"
                                className="form-label"
                              >
                                Total Raise
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="total_raise"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.total_raise}
                                id="total_raise"
                              />
                              {errors.total_raise && (
                                <span style={{ color: "red" }}>
                                  {errors.total_raise}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="min_allocation"
                                className="form-label"
                              >
                                Min. Allocation
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="min_allocation"
                                onChange={handleChange}
                                onKeyPress={validNumber}
                                autoComplete="off"
                                value={formData.min_allocation}
                                id="min_allocation"
                              />
                              {errors.min_allocation && (
                                <span style={{ color: "red" }}>
                                  {errors.min_allocation}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="max_allocation"
                                className="form-label"
                              >
                                Max. Allocation
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="max_allocation"
                                onKeyPress={validNumber}
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.max_allocation}
                                id="max_allocation"
                              />
                              {errors.max_allocation && (
                                <span style={{ color: "red" }}>
                                  {errors.max_allocation}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="total_distribution"
                                className="form-label"
                              >
                                Token Distribution
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="total_distribution"
                                onChange={handleChange}
                                // onKeyPress={validNumber}
                                autoComplete="off"
                                value={formData.total_distribution}
                                id="tokenName"
                              />
                              {errors.total_distribution && (
                                <span style={{ color: "red" }}>
                                  {errors.total_distribution}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="incubation_option
"
                                className="form-label"
                              >
                                Incubations
                              </label>

                              {/* <div>
                                <label>
                                  {console.log(
                                    formData.incubation_option,
                                    "incu"
                                  )}
                                  <input
                                    type="radio"
                                    name="incubation_option
"
                                    value="Yes"
                                    checked={
                                      formData.incubation_option == "Yes"
                                    }
                                    onChange={handleChange}
                                  />
                                  Yes
                                </label>
                                <label style={{ marginLeft: "10px" }}>
                                  <input
                                    type="radio"
                                    name="incubation_option
"
                                    value="No"
                                    checked={formData.incubation_option == "No"}
                                    onChange={handleChange}
                                  />
                                  No
                                </label>
                              </div> */}
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    name="incubation_option"
                                    value="Yes"
                                    checked={
                                      formData.incubation_option === "Yes"
                                    }
                                    onChange={handleChange}
                                  />
                                  Yes
                                </label>
                                <label style={{ marginLeft: "10px" }}>
                                  <input
                                    type="radio"
                                    name="incubation_option"
                                    value="No"
                                    checked={
                                      formData.incubation_option === "No"
                                    }
                                    onChange={handleChange}
                                  />
                                  No
                                </label>
                              </div>

                              {errors.incubation_option && (
                                <span style={{ color: "red" }}>
                                  {errors.incubation_option}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="sale_starts_in"
                                className="form-label"
                              >
                                Sale Starts In
                              </label>
                              <input
                                className="form-control"
                                type="datetime-local"
                                name="sale_starts_in"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.sale_starts_in}
                                id="sale_starts_in"
                              />
                              {errors.sale_starts_in && (
                                <span style={{ color: "red" }}>
                                  {errors.sale_starts_in}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="sale_ends_in"
                                className="form-label"
                              >
                                Sale Ends In
                              </label>
                              <input
                                className="form-control"
                                type="datetime-local"
                                name="sale_ends_in"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.sale_ends_in}
                                id="sale_ends_in"
                              />
                              {errors.sale_ends_in && (
                                <span style={{ color: "red" }}>
                                  {errors.sale_ends_in}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="project_summary"
                                className="form-label"
                              >
                                Project Summary
                              </label>
                              <textarea
                                className="form-control"
                                name="project_summary"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.project_summary}
                                id="project_summary"
                                rows={6}
                                // columns={12}
                              ></textarea>
                              {errors.project_summary && (
                                <span style={{ color: "red" }}>
                                  {errors.project_summary}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="vesting_details"
                                className="form-label"
                              >
                                Vesting Details
                              </label>
                              <textarea
                                className="form-control"
                                name="vesting_details"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.vesting_details}
                                id="vesting_details"
                                rows={6}
                                // columns={12}
                              ></textarea>
                              {errors.vesting_details && (
                                <span style={{ color: "red" }}>
                                  {errors.vesting_details}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="website" className="form-label">
                                  Website
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="website"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  value={formData.website}
                                  id="website"
                                  readOnly=""
                                />
                                {errors.website && (
                                  <span style={{ color: "red" }}>
                                    {errors.website}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="twitter" className="form-label">
                                  Twitter
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="twitter"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  value={formData.twitter}
                                  id="twitter"
                                />
                                {errors.twitter && (
                                  <span style={{ color: "red" }}>
                                    {errors.twitter}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label
                                  htmlFor="telegram"
                                  className="form-label"
                                >
                                  Telegram
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="telegram"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  value={formData.telegram}
                                  id="telegram"
                                />
                                {errors.telegram && (
                                  <span style={{ color: "red" }}>
                                    {errors.telegram}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="discord" className="form-label">
                                  Discord
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="discord"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  value={formData.discord}
                                  id="discord"
                                />
                                {errors.discord && (
                                  <span style={{ color: "red" }}>
                                    {errors.discord}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="participants"
                              className="form-label"
                            >
                              Participants
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="participants"
                              onChange={handleChange}
                              onKeyPress={validNumber}
                              autoComplete="off"
                              value={formData.participants}
                              id="participants"
                              readOnly=""
                            />
                            {errors.participants && (
                              <span style={{ color: "red" }}>
                                {errors.participants}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="token_name" className="form-label">
                              Token Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="token_name"
                              onChange={handleChange}
                              autoComplete="off"
                              value={formData.token_name}
                              id="token_name"
                            />
                            {errors.token_name && (
                              <span style={{ color: "red" }}>
                                {errors.token_name}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="token_symbol"
                              className="form-label"
                            >
                              Token Symbol
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="token_symbol"
                              onChange={handleChange}
                              autoComplete="off"
                              value={formData.token_symbol}
                              id="token_symbol"
                            />
                            {errors.token_symbol && (
                              <span style={{ color: "red" }}>
                                {errors.token_symbol}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="token_decimal"
                              className="form-label"
                            >
                              Token Decimal
                            </label>

                            <input
                              className="form-control"
                              type="number"
                              name="token_decimal"
                              onChange={handleChange}
                              autoComplete="off"
                              value={formData.token_decimal}
                              id="token_decimal"
                            />
                            {errors.token_decimal && (
                              <span style={{ color: "red" }}>
                                {errors.token_decimal}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* 2nd area */}

                      <div className="box-body gray-box-body-dark">
                        <div className="row">
                          <div className="form-group">
                            <h4>Allocation</h4>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label
                                  htmlFor="token_price"
                                  className="form-label"
                                >
                                  Token Price
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="token_price"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  onKeyPress={validNumber}
                                  value={formData.token_price}
                                  id="token_price"
                                />
                                {errors.token_price && (
                                  <span style={{ color: "red" }}>
                                    {errors.token_price}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label
                                  htmlFor="token_equivalent_usdt"
                                  className="form-label"
                                >
                                  Token equivalent to 1USDT
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="token_equivalent_usdt"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  value={formData.token_equivalent_usdt}
                                  id="token_equivalent_usdt"
                                  disabled
                                />
                                {errors.token_equivalent_usdt && (
                                  <span style={{ color: "red" }}>
                                    {errors.token_equivalent_usdt}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" gray-box-body">
                        <div className="row">
                          <div className="form-group"></div>
                          {/* <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="allocationStartDate"
                                className="form-label"
                              >
                                Allocation Start in
                              </label>
                              <input
                                className="form-control"
                                type="datetime-local"
                                name="allocation_start_date"
                                onChange={handleChange}
                                value={formData.allocation_start_date}
                                id="allocation_start_date"
                                autoComplete="off"
                              />{" "}
                              {errors.allocation_start_date && (
                                <span style={{ color: "red" }}>
                                  {errors.allocation_start_date}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="allocationStartTime"
                                className="form-label"
                              >
                                Allocation ends in
                              </label>
                              <input
                                className="form-control"
                                type="datetime-local"
                                name="allocation_start_time"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.allocation_start_time}
                                id="tokenName"
                              />
                              {errors.allocation_start_time && (
                                <span style={{ color: "red" }}>
                                  {errors.allocation_start_time}
                                </span>
                              )}
                            </div>
                          </div> */}

                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="token_no
"
                                className="form-label"
                              >
                                Total No. of Token
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                name="token_no"
                                onKeyPress={validNumber}
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.token_no}
                                id="token_no"
                              />
                              {errors.token_no && (
                                <span style={{ color: "red" }}>
                                  {errors.token_no}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="smart_contract_for_IDO"
                                className="form-label"
                              >
                                Smart Contract for IDO
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="smart_contract_for_IDO"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.smart_contract_for_IDO}
                                id="smart_contract_for_IDO"
                              />
                              {errors.smart_contract_for_IDO && (
                                <span style={{ color: "red" }}>
                                  {errors.smart_contract_for_IDO}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="accessType"
                                className="form-label"
                              >
                                Access Type Copied
                              </label>

                              <select
                                className="form-select"
                                // name="access_type"

                                autoComplete="off"
                                // value={formData.access_type}
                                disabled={true}
                              >
                                <option>{accessDisabled}</option>
                                {/* {filteredAccessType.map((value, index) => (
                                  <option key={index} value={value.access_type}>
                                    {value}
                                  </option>
                                ))} */}
                              </select>

                              {/* {errors.access_type && (
                                <span style={{ color: "red" }}>
                                  {errors.access_type}
                                </span>
                              )} */}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="accessType"
                                className="form-label"
                              >
                                Access Type
                              </label>

                              <select
                                className="form-select"
                                name="access_type"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.access_type}
                              >
                                <option value="">Select</option>

                                {filteredAccessType.map((value, index) => (
                                  <option key={index} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>

                              {errors.access_type && (
                                <span style={{ color: "red" }}>
                                  {errors.access_type}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                       

                          <div className="col-md-3">
                            {formData.access_type !== "Public" &&
                            formData.access_type !== "Private" ? (
                              <div className="form-group">
                                <label
                                  htmlFor="allocation_xtimes"
                                  className="form-label"
                                >
                                  Allocation x times
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="allocation_xtimes"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  value={formData.allocation_xtimes}
                                  id="allocation_xtimes"
                                />
                                {errors.allocation_xtimes && (
                                  <span style={{ color: "red" }}>
                                    {errors.allocation_xtimes}
                                  </span>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div> */}
                      </div>

                      <div className="box-footer">
                        <button
                          type="submit"
                          className="btn_man"
                          onClick={handleUpdateHandler}
                          disabled={btnDisabled}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
