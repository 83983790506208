import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
// import { adminChangePassword } from "../services/user";
import { useAuth } from "../AuthContext";
import configs from "../Constant/Config";
import { toast } from "react-toastify";
import $ from "jquery"
function Header() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  // const logout = () => {
  //   toast.success("Logout successfully");
  //   setTimeout(() => {
  //     localStorage.clear();
  //     navigate("/");
  //   }, 2000);
  // };
  const adminlogout = () => {
    toast.success("Logout successfully");
    localStorage.clear();
    logout();
    navigate("/")
  };

  useEffect(() => {
    const box = document.getElementById("box1");
      box.addEventListener("click", () => {
        if (box.classList.contains("toggle")) {
          box.classList.remove("toggle");
        } else {
          box.classList.add("toggle");
        }
      });
      $(document).ready(function() {
        // Handle checkbox click
        $(".nav-link1").click(function () {
          $(".body").toggleClass("mobile-menu");
        });

        // Handle nav-link click
        $("#box1").click(function () {
          $(".body").toggleClass("mobile-menu");
        });
      });


  }, [])
 

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
        <div id="box1" className="box2">
            <span></span>
          </div>
          <Link to={"/dashboard"} className="logo_mobile">
            <img src="/assets/images/logo.png" alt="" className="img-fluid" />
          </Link>
          <div className="d-flex align-items-center">
            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn_rt shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="">
                  <i className="mdi mdi-account-outline"></i>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome Admin!</h6>
                {/* <Link
                  className="dropdown-item"
                  to=""
                  onClick={() => handleShow()}
                >
                  <span className="align-middle">Change Password</span>
                </Link> */}
                <Link
                  className="dropdown-item"
                  to=""
                  onClick={(e) => adminlogout(e)}
                >
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </Link>

              
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
