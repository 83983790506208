import React, { useEffect, useState } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { Link, useNavigate } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";
import { getadminApprovedProject } from "../services/Projects";
import { confirmAlert } from "react-confirm-alert";
import { deleteProject } from "../services/Projects";
import { toast } from "react-toastify";
export const ApprovedProject = () => {
  const [record, setRecord] = useState([]);

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "project_name",
      text: "Project Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
      // cell: (record) => {

      //   return <>{record.name}</>;
      // },
    },

    {
      key: "blockchain",
      text: "Blockchain",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "access_type",
      text: "Access Type",
      className: "cust_name",
      align: "left",
      sortable: true,
    },

    // {
    //   key: "created_at",
    //   text: "Created Date",
    //   className: "color",
    //   align: "left",
    //   sortable: true,
    //     cell: (record) => {
    //       return <>{moment(record.created_at).format("LLL")}</>;
    //     },
    // },
    {
      key: "action",
      text: "Action",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        console.log(record,"resvoooo")
        return (
          <>
            <button
              type="button"
              onClick={() => ActionHandler(record?.id)}
              className="btn btn-secondary  me-2"
              title="Delete Project"
            >
              Delete
            </button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = async () => {
    const config = localStorage.getItem("jwtToken");
    const res = await getadminApprovedProject(config);

    if (res.status) {
      setRecord(res.getData);
    }
  };
  const deleteProjects = async (id) => {
    console.log(id, "id");
    const config = localStorage.getItem("jwtToken");
    const data = {
      id,
    };
    console.log(data,"data22")

    const resp = await deleteProject(data, config);
    console.log(resp, "resp");
    if (resp.status) {
      getUsers();
      toast.dismiss();
      toast.success(resp.message);
    } else {
      toast.dismiss();
      toast.error(resp.message);
    }
  };
  const ActionHandler = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `Really want to delete this Project?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteProjects(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex align-items-center">
              <h2 className="mb-0">
                <b>Approved Admin Project</b>
              </h2>
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Approved Admin Project
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Approved Admin Project
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="active ml-auto back_btn">
                {" "}
                <Link className="btn_man" to="/dashboard">
                  Back
                </Link>
              </div>
            </div>

            <div className="box">
              <div className="box-body">
                <div className="">
                  <div className="">
                    <div className="table-responsive">
                      <ReactDatatable
                        config={config}
                        records={record}
                        columns={columns}
                        onPageChange={pageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
