import * as opsService from "./Ops";

import { dashboardDatas,updateProfiles } from "../Constant/Api";

const dashboardData = async (data, token) => {
  let result = await opsService.getData(dashboardDatas, data, token);

  return result;
};
const updateProfile = async (data, token) => {
  let result = await opsService.postdata(updateProfiles, data, token);

  return result;
};



export { dashboardData,updateProfile };
