import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { Link, useNavigate } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { getIdoRequest, statusUpdates, projectDetail } from "../services/Ido";
import { FiEye } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";

export const Ido = () => {
  const [record, setRecord] = useState([]);
  const [records, setRecords] = useState([]);

  const [show, setShow] = useState(false);
  //   <Button variant="primary" onClick={() => setShow(true)}>
  //   Custom Width Modal
  // </Button>
  const handleShow = (id) => {
    setShow(true);
    getDataById(id);
  };
  const handleClose = () => setShow(false);
  const statusHandler = async (type, id) => {
    const config = localStorage.getItem("jwtToken");
    let data = {
      type,
      id,
    };
    const res = await statusUpdates(data, config);
    if (res.status) {
      toast.dismiss();
      toast.success(res.message);
      getIdo();
    } else {
      toast.dismiss();
      toast.success(res.message);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
  }, []);

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        console.log(record.name, "hhhhhhhnamme");
        return <>{record.name}</>;
      },
    },
    {
      key: "email",
      text: "Email",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "project_name",
      text: "Project Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },

    {
      key: "created_date",
      text: "Created Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.created_at).format("LLL")}</>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.status == "Pending" ? (
          <>
            <i
              onClick={() => approval("Verified", record.id)}
              className="fa fa-check"
              title="approve"
              style={{
                color: "#fff",
                fontSize: "20px",
                cursor: "pointer",
                backgroundColor: "green",
              }}
            ></i>
            <i
              onClick={() => reject("Reject", record.id)}
              className="fa fa-times ms-3"
              title="reject"
              style={{
                color: "#fff",
                fontSize: "20px",
                cursor: "pointer",
                backgroundColor: "red",
                // textAlign: "center",
                // borderRadius: "5px",
                // padding: "6px",
              }}
            ></i>
          </>
        ) : (
          <h6
            style={{
              backgroundColor: record.status == "Verified" ? "green" : "red",
              color: "#fff",

              textAlign: "center",
              borderRadius: "5px",

              padding: "6px",
            }}
          >
            {record.status}
          </h6>
        );
      },
    },

    {
      key: "action",
      text: "Action",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <div style={{ cursor: "pointer", fontSize: "22px" }} title="view">
            <FiEye onClick={() => handleShow(record.id)} />
          </div>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};

  const approval = (type, record) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to approve this project?",
      buttons: [
        {
          label: "Yes",
          onClick: () => statusHandler(type, record),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const reject = (type, record) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to reject this project?",
      buttons: [
        {
          label: "Yes",
          onClick: () => statusHandler(type, record),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getIdo = async () => {
    const config = localStorage.getItem("jwtToken");
    const res = await getIdoRequest(config);

    if (res.status) {
      console.log(res, "resss");
      setRecord(res.data);
    }
  };
  useEffect(() => {
    getIdo();
  }, []);

  const getDataById = async (id) => {
    const config = localStorage.getItem("jwtToken");
    let data = {
      id,
    };

    const res = await projectDetail(data, config);
    console.log(res.data, "res");
    if (res.status) {
      setRecords(res.data);
    }
  };

  return (
    <>
      {" "}
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading d-flex align-items-center">
                <h2 className="mb-0">
                  <b>IDO Request</b>
                </h2>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/dashboard">
                          <i className="mdi mdi-home-outline"></i>
                        </a>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        IDO Request
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        IDO Request
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="active ml-auto back_btn">
                  {" "}
                  <Link className="btn_man" to="/dashboard">
                    Back
                  </Link>
                </div>
              </div>

              <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Project Details
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {records.map((records) => {
                    return (
                      <Container>
                        <Row>
                          <Col xs={12} md={6}>
                            <p style={{ fontSize: "17px" }}>Project Name</p>
                            <div>
                              {" "}
                              <p style={{ fontSize: "14px" }}>
                                {records?.name}
                              </p>
                            </div>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5> Project About</h5>
                            <p style={{ fontSize: "14px" }}>{records?.about}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>Project Status</h5>
                            <p style={{ fontSize: "14px" }}>{records.status}</p>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5> Can you describe simply the token use case</h5>
                            <p style={{ fontSize: "14px" }}>
                              {records?.describes}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>Have you already raised funds?</h5>
                            <p style={{ fontSize: "14px" }}>{records?.funds}</p>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5>
                              {" "}
                              Is your project designed for BSC or are you
                              migrating from somewhere else?
                            </h5>
                            <p style={{ fontSize: "14px" }}>
                              {records?.designed}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>Is your team Anon or public</h5>
                            <p style={{ fontSize: "14px" }}>{records?.team} </p>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5>
                              {" "}
                              How much are you planning on raising on the IDO?
                            </h5>
                            <p style={{ fontSize: "14px" }}>
                              {records?.planning}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>If yes, how much?</h5>
                            <p style={{ fontSize: "14px" }}>
                              {records?.funds}{" "}
                            </p>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5> Website URL</h5>
                            <p style={{ fontSize: "14px" }}>
                              {records?.website_url}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>Medium link</h5>
                            <p style={{ fontSize: "14px" }}>
                              {records?.medium_link}{" "}
                            </p>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5> Telegram group</h5>
                            <p style={{ fontSize: "14px" }}>
                              {records?.telegram_group}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>Your TG Handle </h5>
                            <p style={{ fontSize: "14px" }}>
                              {records?.describes}{" "}
                            </p>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5> Email</h5>
                            <p style={{ fontSize: "14px" }}>{records?.email}</p>
                          </Col>
                        </Row>
                      </Container>
                    );
                  })}
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
              </Modal>
              <div className="box">
                <div className="box-body">
                  <div className="">
                    <div className="">
                      <div className="table-responsive">
                        <ReactDatatable
                          config={config}
                          records={record}
                          columns={columns}
                          onPageChange={pageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
