import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Widgets/Dashboard";
import { useAuth } from "./AuthContext";

import Userprofile from "./Pages/Userprofile";
import { Ido } from "./Pages/Ido";
import { ToastContainer } from "react-toastify";

import { Approve } from "./Pages/Approve";
import { NewProjectAdd } from "./partials/NewProjectAdd";
import { ProjectUpdate } from "./partials/ProjectUpdate";
import { ProjectAdd } from "./partials/ProjectAdd";
import ProtectedRoute from "./ProtectedRoute";
import ProjectInQueue from "./Pages/ProjectInQueue";
import { ApprovedProject } from "./Pages/ApprovedProject";
function App() {
  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <>
  

      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          <Route
            path="/approve"
            element={<ProtectedRoute component={<Approve />} />}
          />
          <Route
            path="/ido-request"
            element={<ProtectedRoute component={<Ido />} />}
          />
          <Route
            path="/project-update/:id"
            element={<ProtectedRoute component={<ProjectUpdate />} />}
          />
          <Route
            path="/project-add/:id"
            element={<ProtectedRoute component={<ProjectAdd />} />}
          />
          <Route
            path="/user-profile"
            element={<ProtectedRoute component={<Userprofile />} />}
          />
          <Route
            path="/copy-project/:id"
            element={<ProtectedRoute component={<NewProjectAdd />} />}
          />
            <Route
            path="/project-in-queue"
            element={<ProtectedRoute component={<ProjectInQueue />} />}
          />
          <Route
            path="/approved-by-admin"
            element={<ProtectedRoute component={<ApprovedProject />} />}
          />
          
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default App;
