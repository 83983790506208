import { baseUrl } from "./BaseUrl";

export const logins = baseUrl + "/login-admin";
export const getCodes = baseUrl + "/getCode";
export const changePassswords = baseUrl + "/changePassword";
export const idoRequests = baseUrl + "/get-ido-request";
export const statusUpdate = baseUrl + "/status-update";

export const projectDetails = baseUrl + "/get-project-details";
export const IdoAdd = baseUrl + "/project-ido-add-update";
export const getIdoDatas = baseUrl + "/get-ido-data";
export const adminAdd = baseUrl + "/admin-create";
export const getAdmin = baseUrl + "/get-admin-data";
export const actionHandle = baseUrl + "/action-activities";
export const adminDataByIds = baseUrl + "/admin-data-by-id";
export const getContactUs = baseUrl + "/get-contact-us";
export const projectAdd = baseUrl + "/project-add";
export const projectById = baseUrl + "/project-data-by-id";
export const projectUpdates = baseUrl + "/project-update";
export const projectCopy = baseUrl + "/project-copy";
export const dashboardDatas = baseUrl + "/get-dashboard-data";
export const projectDataInQueue = baseUrl + "/get-project-data-queue";
export const adminApprovedProject = baseUrl + "/approved-by-admin";
export const getAccessTypes = baseUrl + "/get-acccess-type";
export const deleteProjects = baseUrl + "/delete-project";
export const updateProfiles = baseUrl + "/update-profile";









