import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import Footer from "../Widgets/Footer";
import { Link, useNavigate } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";
import { getIdoData } from "../services/Ido";
import { deleteProject } from "../services/Projects";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

export const Approve = () => {
  const [record, setRecord] = useState([]);
  console.log(record, "record2");

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "project_name",
      text: "Project Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "blockchain",
      text: "Blockchain",
      className: "blockchain.",
      align: "left",
      sortable: true,
    },
    {
      key: "Project added",
      text: " Project Added",
      className: "blockchain.",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record?.status == "Verified" &&
            record?.admin_status == "Verified" &&
            record?.user_id ? (
              <b>Added</b>
            ) : (
              "Pending"
            )}
          </>
        );
      },
    },
    {
      key: "access_type",
      text: "Access Type",
      className: "blockchain.",
      align: "left",
      sortable: true,
    },

    {
      key: "created_date",
      text: "Created Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.created_at).format("LLL")}</>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <h6
            style={{
              backgroundColor: "green",
              color: "#fff",
              textAlign: "center",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            {record.status}
          </h6>
        );
      },
    },

    {
      key: "action",
      text: "Action",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: ({
        id,
        parent_project_id,
        status,
        admin_status,
        user_id,
        sale_starts,
      }) => {
        const isVerified =
          status === "Verified" && admin_status === "Verified" && user_id;
        const isDisabled = isVerified ? true : false;
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);
        console.log(currentTimestamp);
        const isLaunched = currentTimestamp < sale_starts ? true : false;
        

        return (
          <>
            {!parent_project_id && (
              <>
                {isDisabled ? (
                  <span className="disabled-link">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      title="Add More Project Details"
                      disabled={isDisabled}
                    >
                      Add
                    </button>
                  </span>
                ) : (
                  <Link to={`/project-add/${id}`}>
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      title="Add More Project Details"
                      disabled={isDisabled}
                    >
                      Add
                    </button>
                  </Link>
                )}
                <Link to={`/copy-project/${id}`}>
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    title="Copy Project"
                  >
                    Copy
                  </button>
                </Link>
              </>
            )}
            {isLaunched ? (
              <Link
                to={`/project-update/${id}`}
                className="me-2 btn btn-secondary icon_btn"
              >
                <i className="fa fa-edit" title="Edit"></i>
              </Link>
            ) : (
              <button
                type="button"
                className="btn btn-secondary me-2"
                title="Edit"
                disabled={true}
              >
                <i className="fa fa-edit" title="Edit"></i>
              </button>
            )}

            {/* Uncomment the delete button when needed */}
            {/* <button
              type="button"
              onClick={() => ActionHandler(id)}
              className="btn btn-secondary me-2"
              title="Delete Project"
            >
              Delete
            </button> */}
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};
  useEffect(() => {
    getIdo();
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
  }, []);
  const deleteProjects = async (id) => {
    console.log(id, "id");
    const config = localStorage.getItem("jwtToken");
    const data = {
      id,
    };

    const resp = await deleteProject(data, config);
    console.log(resp, "resp");
    if (resp.status) {
      getIdo();
      toast.dismiss();
      toast.success(resp.message);
    } else {
      toast.dismiss();
      toast.error(resp.message);
    }
  };
  const ActionHandler = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `Really want to delete this Project?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteProjects(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const getIdo = async () => {
    const config = localStorage.getItem("jwtToken");
    const res = await getIdoData(config);
    console.log(res, "res11");

    if (res.status) {
      console.log(res, "res.getVerify");
      setRecord(res.getVerify);
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex align-items-center">
              <h2 className="mb-0">
                <b>Approve Projects</b>
              </h2>
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Approve Projects
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Approve Projects
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="active ml-auto back_btn">
                {" "}
                <Link className="btn_man" to="/dashboard">
                  Back
                </Link>
              </div>
            </div>

            <div className="box">
              <div className="box-body">
                <div className="">
                  <div className="">
                    <div className="table-responsive">
                      <ReactDatatable
                        config={config}
                        records={record}
                        columns={columns}
                        onPageChange={pageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
