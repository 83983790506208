const inputChangePassword = (name, value) => {
    const whiteSpacePattern = /^\s/;
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#%?^&*]).{8,}$/;
  
    if (!value) {
      return "This field is required";
    }
    if (whiteSpacePattern.test(value)) {
      return "Space not alllowed";
    }
  
    if (name === "new_password" && !passwordPattern.test(value)) {
      return "Password must be 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character";
    }
  
    return ""; // No errors
  };
  
  export { inputChangePassword };
  