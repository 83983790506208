import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addProject } from "../services/Projects";
import { toast } from "react-toastify";

export const ProjectAdd = () => {
  const initialValues = {
    tokenAddress: "",
    blockchainType: "",
    totalSupply: "",
    projectLogo: "",
    projectName: "",
    totalRaise: "",
    maxAllocation: "",
    minAllocation: "",
    totalDistribution: "",
    incubationOption: "",
    saleStartsIn: "",
    saleEndsIn: "",
    projectSummary: "",
    vestingDetails: "",
    website: "",
    twitter: "",
    telegram: "",
    discord: "",
    participants: "",
    tokenName: "",
    tokenSymbol: "",
    tokenDecimal: "",
    tokenPrice: "",
    tokenEquivalentUsdt: "",
    // allocationStartDate: "",
    // allocationStartTime: "",
    // allocationEndingHours: "",
    tokenNoSell: "",
    SmartContractForIDO: "",
    access_type: "",
    // allocation_xtimes: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;

  //     let error = "";

  //     if (value === "") {
  //       error = "This field is required.";
  //     }

  //     if (name === "projectLogo") {
  //       const file = e.target.files[0];

  //       setFormData({
  //         ...formData,
  //         [name]: file,
  //       });
  //     } else {
  //       setFormData({
  //         ...formData,
  //         [name]: value,
  //       });
  //     }
  // if(name === "tokenPrice"){
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // }

  //     setErrors({
  //       ...errors,
  //       [name]: error,
  //     });
  //   };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let error = "";

    if (value === "") {
      error = "This field is required.";
    }

    if (name === "projectLogo") {
      const file = e.target.files[0];

      setFormData({
        ...formData,
        [name]: file,
      });
    } else {
      if (name === "tokenPrice") {
        const tokenPrice = parseFloat(value);
        const tokenEquivalentUsdt = tokenPrice !== 0 ? 1 / tokenPrice : "";
        setFormData({
          ...formData,
          [name]: value,
          tokenEquivalentUsdt: tokenEquivalentUsdt,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    const newErrors = {};

    const unixTimestamp = Math.floor(
      new Date(formData.saleStartsIn).getTime() / 1000
    );
    const unixTimestamp2 = Math.floor(
      new Date(formData.saleEndsIn).getTime() / 1000
    );
    const currentTime = new Date().getTime() / 1000;

    // const allocationStrt = Math.floor(
    //   new Date(formData.allocationStartDate).getTime() / 1000
    // );
    // const allocationEnds = Math.floor(
    //   new Date(formData.allocationStartTime).getTime() / 1000
    // );
    if (
      parseFloat(formData.minAllocation) >= parseFloat(formData.maxAllocation)
    ) {
      toast.error("Max Allocation should be greater than min Allocation");
      return;
    }
    if (currentTime > unixTimestamp) {
      toast.error("Invalid sale start time ");
      return;
    }

    if (currentTime > unixTimestamp2) {
      toast.error("Invalid sale end time ");
      return;
    }
    if (unixTimestamp >= unixTimestamp2) {
      toast.error("Sale end time should be greater than sale start time");
      return;
    }
    const noStartingSpaceRegex = /^[^\s].*$/;
    if (!noStartingSpaceRegex.test(formData.projectName)) {
      toast.error("Space not allowed");
      return;
    }

    for (const key in formData) {
      if (formData[key] == "") {
        console.log(formData[key], "formData[key]");
        newErrors[key] = "This field is required.";
        isValid = false;
        break;
      }
    }

    setErrors(newErrors);
    if (formData.projectLogo && formData.projectLogo.size > 1024 * 1024) {
      toast.dismiss();
      toast.error(
        "'File size exceeds 1MB limit. Please choose a smaller file.'"
      );
    }

    if (isValid) {
      setBtnDisabled(true);
      try {
        const formDatas = new FormData();
        const config = localStorage.getItem("jwtToken");

        for (const key in formData) {
          formDatas.append(key, formData[key]);
        }

        formDatas.append("saleStartsIn", JSON.stringify(unixTimestamp));
        formDatas.append("saleEndsIn", JSON.stringify(unixTimestamp2));
        // formDatas.append("allocationStartDate", JSON.stringify(allocationStrt));
        // formDatas.append("allocationStartTime", JSON.stringify(allocationEnds));
        formDatas.append("id", id);

        const res = await addProject(formDatas, config);

        if (res.status) {
          setBtnDisabled(false);
          toast.dismiss();
          toast.success(res.message);
          navigate("/approve");
        } else {
          toast.dismiss();
          toast.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const options = [];
  for (let i = 1; i <= 24; i++) {
    options.push(
      <option key={i} value={i}>
        {i} Hours
      </option>
    );
  }

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex align-items-center">
              <h2 className="mb-0">
                <b>Add Project Details</b>
              </h2>
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Project
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add Project Details
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="active ml-auto back_btn">
                {" "}
                <Link className="btn_man" to="/approve">
                  Back
                </Link>
              </div>
            </div>
            <div className="box alert_message_step">
              <div className="box-body">
                <div className="">
                  <div className="">
                    <form>
                      <div className="box-body gray-box-body">
                        <div className="row">
                          <div className="form-group">
                            <h4>Token Details</h4>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="tokenAddress"
                                className="form-label"
                              >
                                Token Contract
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="tokenAddress"
                                autoComplete="off"
                                onChange={handleChange}
                                value={formData.tokenAddress}
                                id="tokenAddress"
                                // autoComplete="off"
                              />
                              {errors.tokenAddress && (
                                <span style={{ color: "red" }}>
                                  {errors.tokenAddress}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="blockchainType"
                                className="form-label"
                              >
                                BlockChain Type
                              </label>
                              <select
                                className="form-select"
                                name="blockchainType"
                                onChange={handleChange}
                                value={formData.blockchainType}
                              >
                                <option>Select</option>
                                <option value="BSC"> BSC </option>
                                <option value="ETH"> ETH </option>
                                <option value="POLYGON"> POLYGON </option>
                              </select>
                              {errors.blockchainType && (
                                <span style={{ color: "red" }}>
                                  {errors.blockchainType}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="totalSupply"
                                className="form-label"
                              >
                                Total Supply
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="totalSupply"
                                onChange={handleChange}
                                // onKeyPress={validNumber}
                                value={formData.totalSupply}
                                autoComplete="off"
                                id="totalSupply"
                              />
                              {errors.totalSupply && (
                                <span style={{ color: "red" }}>
                                  {errors.totalSupply}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="whitelist" className="form-label">
                                Project Logo
                              </label>
                              <img
                                src={formData.projectLogo}
                                id="imgSample"
                                alt=""
                              />
                              <input
                                className="form-control"
                                type="file"
                                name="projectLogo"
                                id="projectLogo"
                                onChange={handleChange}
                                // value={}
                              />
                              {errors.projectLogo && (
                                <span style={{ color: "red" }}>
                                  {errors.projectLogo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="projectName"
                                className="form-label"
                              >
                                Project Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="projectName"
                                onChange={handleChange}
                                value={formData.projectName}
                                id="projectName"
                                autoComplete="off"
                              />
                              {errors.projectName && (
                                <span style={{ color: "red" }}>
                                  {errors.projectName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="totalRaise"
                                className="form-label"
                              >
                                Total Raise
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="totalRaise"
                                autoComplete="off"
                                onChange={handleChange}
                                value={formData.totalRaise}
                                onKeyPress={validNumber}
                                id="totalRaise"
                              />
                              {errors.totalRaise && (
                                <span style={{ color: "red" }}>
                                  {errors.totalRaise}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="minAllocation"
                                className="form-label"
                              >
                                Min. Allocation
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="minAllocation"
                                onChange={handleChange}
                                value={formData.minAllocation}
                                autoComplete="off"
                                onKeyPress={validNumber}
                                id="minAllocation"
                              />
                              {errors.minAllocation && (
                                <span style={{ color: "red" }}>
                                  {errors.minAllocation}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="maxAllocation"
                                className="form-label"
                              >
                                Max. Allocation
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="maxAllocation"
                                onKeyPress={validNumber}
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.maxAllocation}
                                id="maxAllocation"
                              />
                              {errors.maxAllocation && (
                                <span style={{ color: "red" }}>
                                  {errors.maxAllocation}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="totalDistribution"
                                className="form-label"
                              >
                                Token Distribution
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="totalDistribution"
                                autoComplete="off"
                                onChange={handleChange}
                                value={formData.totalDistribution}
                                // onKeyPress={validNumber}
                                id="tokenName"
                              />
                              {errors.totalDistribution && (
                                <span style={{ color: "red" }}>
                                  {errors.totalDistribution}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="incubationOption"
                                className="form-label"
                              >
                                Incubations
                              </label>

                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    name="incubationOption"
                                    value="Yes"
                                    checked={
                                      formData.incubationOption === "Yes"
                                    }
                                    onChange={handleChange}
                                  />
                                  Yes
                                </label>
                                <label style={{ marginLeft: "10px" }}>
                                  <input
                                    type="radio"
                                    name="incubationOption"
                                    value="No"
                                    checked={formData.incubationOption === "No"}
                                    onChange={handleChange}
                                  />
                                  No
                                </label>
                              </div>
                              {errors.incubationOption && (
                                <span style={{ color: "red" }}>
                                  {errors.incubationOption}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="saleStartsIn"
                                className="form-label"
                              >
                                Sale Starts In
                              </label>
                              <input
                                className="form-control"
                                type="datetime-local"
                                name="saleStartsIn"
                                onChange={handleChange}
                                value={formData.saleStartsIn}
                                autoComplete="off"
                                id="saleStartsIn"
                              />
                              {errors.saleStartsIn && (
                                <span style={{ color: "red" }}>
                                  {errors.saleStartsIn}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="saleEndsIn"
                                className="form-label"
                              >
                                Sale Ends In
                              </label>
                              <input
                                className="form-control"
                                type="datetime-local"
                                name="saleEndsIn"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.saleEndsIn}
                                id="saleEndsIn"
                              />
                              {errors.saleEndsIn && (
                                <span style={{ color: "red" }}>
                                  {errors.saleEndsIn}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="projectSummary"
                                className="form-label"
                              >
                                Project Summary
                              </label>
                              <textarea
                                className="form-control"
                                name="projectSummary"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.projectSummary}
                                id="projectSummary"
                                rows={6}
                                // columns={12}
                              ></textarea>
                              {errors.projectSummary && (
                                <span style={{ color: "red" }}>
                                  {errors.projectSummary}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="vestingDetails"
                                className="form-label"
                              >
                                Vesting Details
                              </label>
                              <textarea
                                className="form-control"
                                name="vestingDetails"
                                onChange={handleChange}
                                value={formData.vestingDetails}
                                id="vestingDetails"
                                autoComplete="off"
                                rows={6}
                                // columns={12}
                              ></textarea>
                              {errors.vestingDetails && (
                                <span style={{ color: "red" }}>
                                  {errors.vestingDetails}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="website" className="form-label">
                                  Website
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="website"
                                  onChange={handleChange}
                                  value={formData.website}
                                  autoComplete="off"
                                  id="website"
                                  readOnly=""
                                />
                                {errors.website && (
                                  <span style={{ color: "red" }}>
                                    {errors.website}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="twitter" className="form-label">
                                  Twitter
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="twitter"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  value={formData.twitter}
                                  id="twitter"
                                />
                                {errors.twitter && (
                                  <span style={{ color: "red" }}>
                                    {errors.twitter}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label
                                  htmlFor="telegram"
                                  className="form-label"
                                >
                                  Telegram
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="telegram"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  value={formData.telegram}
                                  id="telegram"
                                />
                                {errors.telegram && (
                                  <span style={{ color: "red" }}>
                                    {errors.telegram}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="discord" className="form-label">
                                  Discord
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="discord"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  value={formData.discord}
                                  id="discord"
                                />
                                {errors.discord && (
                                  <span style={{ color: "red" }}>
                                    {errors.discord}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="participants"
                              className="form-label"
                            >
                              Participants
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="participants"
                              onChange={handleChange}
                              onKeyPress={validNumber}
                              autoComplete="off"
                              value={formData.participants}
                              id="participants"
                              readOnly=""
                            />
                            {errors.participants && (
                              <span style={{ color: "red" }}>
                                {errors.participants}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="tokenName" className="form-label">
                              Token Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="tokenName"
                              onChange={handleChange}
                              autoComplete="off"
                              value={formData.tokenName}
                              id="tokenName"
                            />
                            {errors.tokenName && (
                              <span style={{ color: "red" }}>
                                {errors.tokenName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="tokenSymbol" className="form-label">
                              Token Symbol
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="tokenSymbol"
                              autoComplete="off"
                              onChange={handleChange}
                              value={formData.tokenSymbol}
                              id="tokenSymbol"
                            />
                            {errors.tokenSymbol && (
                              <span style={{ color: "red" }}>
                                {errors.tokenSymbol}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              htmlFor="tokenDecimal"
                              className="form-label"
                            >
                              Token Decimal
                            </label>

                            <input
                              className="form-control"
                              type="number"
                              name="tokenDecimal"
                              onChange={handleChange}
                              autoComplete="off"
                              value={formData.tokenDecimal}
                              id="tokenDecimal"
                            />
                            {errors.tokenDecimal && (
                              <span style={{ color: "red" }}>
                                {errors.tokenDecimal}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* 2nd area */}

                      <div className="box-body gray-box-body-dark">
                        <div className="row">
                          <div className="form-group">
                            <h4>Allocation</h4>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label
                                  htmlFor="tokenPrice"
                                  className="form-label"
                                >
                                  Token Price
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="tokenPrice"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  onKeyPress={validNumber}
                                  value={formData.tokenPrice}
                                  id="tokenPrice"
                                />
                                {errors.tokenPrice && (
                                  <span style={{ color: "red" }}>
                                    {errors.tokenPrice}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label
                                  htmlFor="tokenEquivalentUsdt"
                                  className="form-label"
                                >
                                  Token equivalent to 1USDT
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  autoComplete="off"
                                  name="tokenEquivalentUsdt"
                                  onChange={handleChange}
                                  disabled
                                  value={formData.tokenEquivalentUsdt}
                                  id="tokenEquivalentUsdt"
                                />
                                {errors.tokenEquivalentUsdt && (
                                  <span style={{ color: "red" }}>
                                    {errors.tokenEquivalentUsdt}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box-body gray-box-body">
                        <div className="row">
                          <div className="form-group"></div>
                          {/* <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="allocationStartDate"
                                className="form-label"
                              >
                                Allocation Start in
                              </label>
                              <input
                                className="form-control"
                                type="datetime-local"
                                name="allocationStartDate"
                                onChange={handleChange}
                                value={formData.allocationStartDate}
                                id="allocationStartDate"
                                autoComplete="off"
                              />{" "}
                              {errors.allocationStartDate && (
                                <span style={{ color: "red" }}>
                                  {errors.allocationStartDate}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="allocationStartTime"
                                className="form-label"
                              >
                                Allocation ends in
                              </label>
                              <input
                                className="form-control"
                                type="datetime-local"
                                name="allocationStartTime"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.allocationStartTime}
                                id="tokenName"
                              />
                              {errors.allocationStartTime && (
                                <span style={{ color: "red" }}>
                                  {errors.allocationStartTime}
                                </span>
                              )}
                            </div>
                          </div> */}

                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="tokenNoSell"
                                className="form-label"
                              >
                                Total No. of Token
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                name="tokenNoSell"
                                onChange={handleChange}
                                onKeyPress={validNumber}
                                autoComplete="off"
                                value={formData.tokenNoSell}
                                id="tokenNoSell"
                              />
                              {errors.tokenNoSell && (
                                <span style={{ color: "red" }}>
                                  {errors.tokenNoSell}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="SmartContractForIDO"
                                className="form-label"
                              >
                                Smart Contract for IDO
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="SmartContractForIDO"
                                onChange={handleChange}
                                autoComplete="off"
                                value={formData.SmartContractForIDO}
                                id="SmartContractForIDO"
                              />
                              {errors.SmartContractForIDO && (
                                <span style={{ color: "red" }}>
                                  {errors.SmartContractForIDO}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="accessType"
                                className="form-label"
                              >
                                Access Type
                              </label>
                              <select
                                className="form-select"
                                name="access_type"
                                onChange={handleChange}
                              >
                                <option>Select</option>
                                <option value="Private"> Private </option>
                                <option value="Private FCFS">
                                  {" "}
                                  Private FCFS
                                </option>
                                <option value="Private FCFS 2">
                                  {" "}
                                  Private FCFS 2{" "}
                                </option>

                                <option value="Public"> Public </option>
                                <option value="Public FCFS">
                                  {" "}
                                  Public FCFS{" "}
                                </option>
                                <option value="Public FCFS 2">
                                  {" "}
                                  Public FCFS 2{" "}
                                </option>
                              </select>
                              {errors.access_type && (
                                <span style={{ color: "red" }}>
                                  {errors.access_type}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="accessType"
                                className="form-label"
                              >
                                Access Type
                              </label>
                              <select
                                className="form-select"
                                name="access_type"
                                onChange={handleChange}
                              >
                                <option>Select</option>
                                <option value="Private"> Private </option>
                                <option value="Private FCFS">
                                  {" "}
                                  Private FCFS
                                </option>
                                <option value="Private FCFS 2">
                                  {" "}
                                  Private FCFS 2{" "}
                                </option>

                                <option value="Public"> Public </option>
                                <option value="Public FCFS">
                                  {" "}
                                  Public FCFS{" "}
                                </option>
                                <option value="Public FCFS 2">
                                  {" "}
                                  Public FCFS 2{" "}
                                </option>
                              </select>
                              {errors.access_type && (
                                <span style={{ color: "red" }}>
                                  {errors.access_type}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                htmlFor="allocation_xtimes"
                                className="form-label"
                              >
                                Allocation x times
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="allocation_xtimes"
                                autoComplete="off"
                                onChange={handleChange}
                                value={formData.allocation_xtimes}
                                id="allocation_xtimes"
                              />
                              {errors.allocation_xtimes && (
                                <span style={{ color: "red" }}>
                                  {errors.allocation_xtimes}
                                </span>
                              )}
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div className="box-footer">
                        <button
                          type="submit"
                          className="btn_man"
                          disabled={btnDisabled}
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
