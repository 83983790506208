import * as opsService from "./Ops";

import {
  projectAdd,
  projectById,
  projectUpdates,
  projectCopy,
  projectDataInQueue,
  adminApprovedProject,
  getAccessTypes,
  deleteProjects
} from "../Constant/Api";

const addProject = async (data, token) => {
  let result = await opsService.postdata(projectAdd, data, token);

  return result;
};
const projectDataById = async (data, token) => {
  let result = await opsService.postdata(projectById, data, token);

  return result;
};
const projectUpdate = async (data, token) => {
  let result = await opsService.postdata(projectUpdates, data, token);

  return result;
};

const projectsCopy = async (data, token) => {
  console.log(data, "data", token);
  let result = await opsService.postdata(projectCopy, data, token);

  return result;
};

const getprojectDataInQueue = async (data, token) => {
  let result = await opsService.getData(projectDataInQueue, data, token);

  return result;
};
const getadminApprovedProject = async (data, token) => {
  let result = await opsService.getData(adminApprovedProject, data, token);

  return result;
};
const getAccessType = async (data, token) => {
  let result = await opsService.postdata(getAccessTypes, data, token);

  return result;
};
const deleteProject = async (data, token) => {
  let result = await opsService.postdata(deleteProjects, data, token);

  return result;
};

export {
  addProject,
  projectDataById,
  projectUpdate,
  projectsCopy,
  getprojectDataInQueue,
  getadminApprovedProject,
  getAccessType,
  deleteProject
};
