import React, { useEffect, useState } from "react";
import { dashboardData } from "../services/user";

const DashboardDataFetcher = () => {
  const [approve, setApprove] = useState("");
  const [inQueue, setInQueue] = useState("");
  const [approvedByAdmin, setApprovedByAdmin] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = localStorage.getItem("jwtToken");
        const res = await dashboardData(config);

        if (res.status) {
          const { approvedProject } = res.getData[0] || {};
          const { queProject } = res.getQueProject[0] || {};
          const { adminApproved } = res.getApprovedProject[0] || {};

          setApprove(approvedProject || 0);
          setInQueue(queProject || 0);
          setApprovedByAdmin(adminApproved || 0);
        } else {
          console.error("Error fetching data:", res.error);
        }
      } catch (error) {
        console.error("Exception while fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return { approve, inQueue, approvedByAdmin };
};

export default DashboardDataFetcher;
