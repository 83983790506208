import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import * as myConstList from "./BaseUrl";
import { useAuth } from "../AuthContext";
import { loginHandle, getCodeHandle } from "../services/auth/auth";
import { Link, useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import env from "react-dotenv";

const Login = () => {
  const serviceIds = env.YOUR_SERVICE_ID;
  const templateIds = env.YOUR_TEMPLATE_ID;
  const publicKeys = env.YOUR_PUBLIC_KEY;

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, seteye] = useState("fa-eye-slash");
  const [showCurrentPassword, setShowCurrentPassword] = useState("password");
  const form = useRef();
  // const [loginVia, setLoginVia] = useState("Password");

  const [loginMethod, setLoginMethod] = useState("password"); // 'password' or 'otp'
  const [timerText, setTimerText] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [counter, setCounter] = useState(0);
  const [textForCode, setTextForCode] = useState("Get Code");
  const [tpin, setTpin] = useState("");
  const [tpinErr, setTpinErr] = useState("");
  const [pin, setPin] = useState("");
  const [pinErr, setPinErr] = useState("");

  const { authenticated, login } = useAuth();
  // useEffect(() => {
  //   if (!localStorage.getItem("jwtToken")) {
  //   } else {
  //     navigate("/dashboard");
  //   }
  // }, []);
  useEffect(() => {
    if (!authenticated) {
      navigate("/", { replace: true });
    } else {
      navigate("/dashboard", { replace: true });
    }
  }, [authenticated, navigate]);

  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      counter--;
      setCounter(counter);
      // setButtonDisableVerification(true);
      setButtonDisable(true);
      setTimerText("Resend In");
      if (counter <= 0) {
        clearInterval(interval);
        setTextForCode("Resend Code");
        setButtonDisable(false);
        // setButtonDisableVerification(false);
        setTimerText("");
        setCounter(0);
      }
    }, 1000);
  };
  useEffect(() => {
    if (counter > 0) {
      startCountdown(counter);
    }
  }, [counter]);

  const showcurrentPassword = () => {
    if (showCurrentPassword === "password") {
      setShowCurrentPassword("text");
      seteye("fa-eye");
    } else {
      setShowCurrentPassword("password");
      seteye("fa-eye-slash");
    }
  };

  const loginInputTypes = async (type, event) => {
    console.log(email, "emailrw");
    if (type === "email") {
      setEmail(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const handleOnBlurEmail = async (event) => {
    var eventValue = await event.target.value;
    var Email1Reg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      email
    );
    if (!eventValue) {
      toast.dismiss();
      toast.error("Enter Email Address");
      return false;
    }

    if (!Email1Reg) {
      toast.dismiss();
      toast.error("Enter valid Email Address");
      return false;
    }
  };
  const handleOnBlurPassword = async (event) => {
    var password = await event.target.value;
    if (!password) {
      toast.dismiss();
      toast.error("Password is required");
      return false;
    }
  };

  const generateRandom6DigitCode = () => {
    const min = 100000; // Smallest 6-digit number
    const max = 999999; // Largest 6-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const onLogin = async (e) => {
    e.preventDefault();
    let loginData = "";
    if (loginMethod === "otp") {
      if (!tpin) {
        setTpinErr("This field is required");
        return false;
      }

      loginData = {
        email,
        tpin,
        type: "otp",
      };

      const resp = await loginHandle(loginData);

      if (!resp.status) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status) {
        let token = resp.token;

        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userType", resp.user_type);
       
        toast.success("Login Successfully");
        setTimeout(function () {
          navigate("/dashboard", { replace: true });
          login();
        }, 2000);
        return false;
      }
    } else {
      loginData = {
        email,
        password,
        pin,
        type: "password",
      };

      const resp = await loginHandle(loginData);

      if (!resp.status) {
        toast.dismiss();
        toast.error(resp.message);
        return;
      }
      if (resp.status) {
        let token = resp.token;

        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userType", resp.user_type);
       
        toast.success("Login Successfully");
        setTimeout(function () {
          navigate("/dashboard", { replace: true });
          login();
        }, 2000);
        return false;
      }
    }
  };

  const getCode = async (e) => {
    e.preventDefault();
    const randomCode = generateRandom6DigitCode();
    const emailParams = {
      from_name: "brain_chain",
      brainchain: "otp",
      serviceId: serviceIds,
      templateId: templateIds,
      userId: publicKeys,

      recipientEmail: email,
      randomCode: randomCode,
    };

    console.log(emailParams.serviceId, "emailParams");
    // Send the email
    emailjs
      .send(
        emailParams.serviceId,
        emailParams.templateId,
        {
          to_email: emailParams.recipientEmail,
          from_name: emailParams.from_name,
          brainchain: emailParams.brainchain,
          message: emailParams.randomCode,
        },
        emailParams.userId
      )
      .then(async (response) => {
        const data = {
          email: emailParams.recipientEmail,
          tpin: randomCode,
        };
        const res = await getCodeHandle(data);
        if (res.status == true) {
          startCountdown(30);
          toast.dismiss();
          setEmail(emailParams.recipientEmail);
          // setTpin(randomCode);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.error("Email sending error:", error);
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id == "inputTPin") {
      if (value == "") {
        setTpinErr("This field is required");
      } else {
        setTpinErr("");
      }
      setTpin(value);
    }
    if (id == "inputPin") {
      if (value == "") {
        setPinErr("This field is required");
      } else {
        setPinErr("");
      }
      setPin(value);
    }
  };

  return (
    <div className="login d-flex nm-aic nm-vh-md-100">
      <div className="Background_container__KTfIm">
        <div className="Background_ElipL__xhjwR"></div>
      </div>
      <div className="container  m-auto">
        <div className="row   align-items-center">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <img
              src="assets/images/loginvector.png"
              alt=""
              className="admin_home_img img-fluid"
            />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <div className="nm-tm-wr">
              <div className="container p-0">
                <div className="container p-0">
                  <img
                    src="assets/images/logo-coin.png"
                    alt=""
                    className="img-fluid login_logo"
                  />
                </div>
                <h4 className="text-center text-white mb-5">Admin Login</h4>
                <form ref={form}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="inputUsername"
                      tabIndex={1}
                      placeholder="Email"
                      required=""
                      onChange={(e) => loginInputTypes("email", e)}
                      onBlur={handleOnBlurEmail}
                    />
                  </div>
                  <div className=" mb-3 password_login">
                    {loginMethod === "password" && (
                      <>
                        <input
                          type={showCurrentPassword}
                          className="form-control password"
                          id="inputPassword"
                          tabIndex={2}
                          placeholder="Password"
                          required=""
                          onChange={(e) => loginInputTypes("password", e)}
                          onBlur={handleOnBlurPassword}
                        />
                        <span
                          role="button"
                          onClick={showcurrentPassword}
                          className="eye-icon"
                        >
                          <i className={`fa ${eye}`}></i>
                        </span>
                        <input
                          type="text"
                          className="form-control mt-3"
                          id="inputPin"
                          placeholder=" Enter PIN"
                          required=""
                          onChange={handleChange}
                        />
                        {pinErr && (
                          <span style={{ color: "red" }} className="mb-5">
                            {pinErr}
                          </span>
                        )}
                      </>
                    )}
                  </div>

                  {loginMethod === "otp" && (
                    <div className="mb-3">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="inputTPin"
                          placeholder="TPIN"
                          required=""
                          onChange={handleChange}
                          disabled={loginMethod === "password"}
                        />

                        <button
                          type="submit"
                          className="btn btn-info"
                          onClick={getCode}
                          disabled={buttonDisable}
                        >
                          {timerText}

                          {counter == 0 ? textForCode : counter}
                        </button>
                      </div>
                      {tpinErr && (
                        <span style={{ color: "red" }} className="mb-5">
                          {tpinErr}
                        </span>
                      )}
                    </div>
                  )}

                  <div className=" nm-aic nm-mb-1">
                    <div className="login-btn ">
                      <button
                        type="submit"
                        className="btn btn_man nm-hvr w100"
                        onClick={onLogin}
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    {loginMethod === "password" ? (
                      <Link to="" onClick={() => setLoginMethod("otp")}>
                        By Using TPIN
                      </Link>
                    ) : (
                      <Link to="" onClick={() => setLoginMethod("password")}>
                        Use Password
                      </Link>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
