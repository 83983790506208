import * as opsService from "../Ops";

import { logins, getCodes, changePassswords } from "../../Constant/Api";

const loginHandle = async (data, token) => {
  let result = await opsService.postdata(logins, data, token);
  console.log(result, "ressultsss");
  return result;
};
const getCodeHandle = async (data, token) => {
  let result = await opsService.postdata(getCodes, data, token);

  // console.log(result,"ressultsss")
  return result;
};
const changePasssword = async (data, token) => {
  let result = await opsService.postdata(changePassswords, data, token);

  return result;
};

export { loginHandle, getCodeHandle, changePasssword };
